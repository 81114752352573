import React, { Fragment, useState, useEffect } from "react";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import logo from "../assets/images/logo/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { loginAction, selectUserError } from "../features/user/userSlices";
import SweetAlert from "sweetalert2";
import { Button } from "antd";
import arrowRight from "../assets/images/icon/arrow-right.svg";
import { ReactSVG } from "react-svg";
import { useTranslation } from "react-i18next";
import logoit from "../assets/images/logo/logo-it.svg";
import Loader from "../Layout/Loader";

const Login = ({ selected }) => {
	const { t } = useTranslation(); // Hook per i18n
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [togglePassword, setTogglePassword] = useState(false);
	const errorMessage = useSelector(selectUserError);
	const dispatch = useDispatch();
	const history = useNavigate();

	const loginAuth = () => {
		const result = dispatch(loginAction({ email, password }));
		result.then((res) => {
			console.log(res);
			if (res.error) {
				SweetAlert.fire({ icon: "error", text: res.payload });
			} else {
				localStorage.setItem("taoToken", res.payload.token);
				history("/home");
			}
		});
	};

	/* 	useEffect(() => {
		if (errorMessage) {
			toast.error(errorMessage);
		}
	}, [errorMessage]); */

	useEffect(() => {
		if (localStorage.getItem("taoToken")) {
			history("/home");
		}
	}, []);

	return (
		<Fragment>
			<div className='container-fluid px-2 px-md-4'>
				<Row>
					<Col className='col-12'>
						<div
							className='ml-3'
							style={{ backgroundColor: "#fff" }}
						>
							<ReactSVG
								src={logoit}
								style={{
									width: "40vw",
									marginTop: "40px",
									marginBottom: "0px",
									maxWidth: "150px",
									cursor: "pointer",
								}}
								onClick={() =>
									(window.location = "https://sanchingfengshui.com/")
								}
								/* style={{
									width: "fit-content",
									marginLeft: "40px",
									marginTop: "40px",
								}} */
							/>
						</div>
						<div className='login-card'>
							<div className='login-main login-tab'>
								<Form className='theme-form'>
									<div
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											flexDirection: "column",
											marginBottom: "80px",
										}}
									>
										<span className='loginTitle'>
											{t("signup.welcome_message")}
										</span>
										<span className='subtitleLogin'>
											{t("signup.login_prompt")}
										</span>
									</div>
									<FormGroup className='mb-4'>
										<Label className='col-form-label'>
											{t("signup.email_address")}
										</Label>
										<Input
											className='form-control'
											type='email'
											required=''
											onChange={(e) => setEmail(e.target.value)}
											defaultValue={email}
											placeholder={t("signup.email_placeholder")}
										/>
									</FormGroup>
									<FormGroup className='position-relative mb-4'>
										<Label className='col-form-label'>
											{t("signup.password")}
										</Label>
										<Input
											className='form-control mb-4'
											type={togglePassword ? "text" : "password"}
											onChange={(e) => setPassword(e.target.value)}
											defaultValue={password}
											required=''
											placeholder={t("signup.password_placeholder")}
										/>
										{/* 			<div className='forgot-psw'>
											<a href={`${process.env.PUBLIC_URL}/auth/reset-password`}>
												<span className='reset-password'>
													{t("signup.forgot_password")}
												</span>
											</a>
										</div> */}
									</FormGroup>
									<div
										className='form-group mb-4'
										style={{
											display: "flex",
											justifyContent: "center",
											alignContent: "center",
										}}
									>
										<Button
											onClick={loginAuth}
											style={{
												backgroundColor: "#477673",
												color: "#fff",
												width: "80%",
												borderRadius: "10px",
												height: "50px",
												textTransform: "uppercase",
												fontSize: "16px",
												fontWeight: "600",
												padding: "10px",
												border: "none",
											}}
										>
											{t("signup.login_button")} <ReactSVG src={arrowRight} />
										</Button>
									</div>
									<div style={{ display: "flex", justifyContent: "center" }}>
										<p style={{ marginRight: "8px" }}>
											{t("signup.no_account")}
										</p>
										<a href={`${process.env.PUBLIC_URL}/auth/signup`}>
											{t("signup.signup_here")}
										</a>
									</div>
								</Form>
							</div>
						</div>
					</Col>
				</Row>
			</div>
		</Fragment>
	);
};

export default Login;
